<template>
  <div class="text-center">
    <v-dialog v-model="showUpdateModel" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Google ads</span>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="AddSampleAdValue()">
            Set Sample Ads
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="App Package Name *"
                    :rules="[(v) => !!v || 'App Package Name is required']"
                    v-model="editGoogleAdForm.appPackageName"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="App Id *"
                    v-model="editGoogleAdForm.appId"
                    required
                  ></v-text-field>
                </v-col>
                
                <v-col cols="6">
                  <v-text-field
                    label="Banner Id *"
                    v-model="editGoogleAdForm.bannerId"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Native Id *"
                    v-model="editGoogleAdForm.nativeId"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="App Open Id *"
                    v-model="editGoogleAdForm.appOpenId"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Reward Video Id *"
                    v-model="editGoogleAdForm.rewardVideoId"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Interstitial Id *"
                    v-model="editGoogleAdForm.interstitialId"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field 
                    v-model="editGoogleAdForm.interstitialCount" 
                    type="number" 
                    label="Interstitial Count"
                    required
                    append-outer-icon="mdi-plus" 
                    @click:append-outer="increment" 
                    prepend-icon="mdi-minus" 
                    @click:prepend="decrement"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-checkbox
                    label="Active"
                    v-model="editGoogleAdForm.isActive"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    label="Active Static Banners"
                    v-model="editGoogleAdForm.isActiveStaticBanners"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    label="Active Qureka"
                    :disabled="disableQurekaBanner"
                    v-model="editGoogleAdForm.isActiveQureka"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    label="Active IGaming"
                    v-model="editGoogleAdForm.isIGaming"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-combobox multiple
                    v-model="editGoogleAdForm.tags" 
                    label="Tags"
                    append-icon
                    chips
                    deletable-chips
                    class="tag-input"
                  >
                  </v-combobox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetUpdateModel()">
            Close
          </v-btn>
          <v-btn color="primary" @click="updateGoogleAd()">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar :text="snackbarText" :showSnackbar="isShowSnackbar" @hide="isShowSnackbar = false"></snackbar>
  </div>
</template>
<script>
import axios from "axios";
import Snackbar from '../Snackbar.vue';
export default {
  name: "DeleteDialog",
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
    googleadDetail: {
      type: Object,
    },
  },
  components: {
    Snackbar,
  },
  data: () => ({
    showUpdateModel: false,
    isAppOrQurekaAd: 'app-ad',
    AppAdId:'',
    QurekaAdId:'qureka ad id',
    editGoogleAdForm:{
      appPackageName: '',
      appId: '',
      bannerId: '',
      nativeId: '',
      interstitialId: '',
      appOpenId: '',
      isActive: true,
      isActiveQureka:false,
      isActiveStaticBanners:false,
      enableQurekaAd:false,
      interstitialCount:0,
      rewardVideoId: '',
      tags:[],
      isIGaming: false
    },
    sampleValue: {
      appId : 'ca-app-pub-3940256099942544~3347511713',
      bannerId : 'ca-app-pub-3940256099942544/6300978111',
      nativeId : 'ca-app-pub-3940256099942544/2247696110',
      interstitialId : 'ca-app-pub-3940256099942544/1033173712',
      appOpenId : 'ca-app-pub-3940256099942544/3419835294',
      rewardVideoId : 'ca-app-pub-3940256099942544/5224354917',
    },
    disableQurekaBanner:true,
    valid: true,
  }),
  watch: {
    showModel(val) {
      this.showUpdateModel = val;
    },
    googleadDetail(val){
        this.editGoogleAdForm = val
    },
    isAppOrQurekaAd(val){
      if(val == 'app-ad'){
        this.editGoogleAdForm.appId = this.AppAdId;
      }else{
        this.editGoogleAdForm.appId = this.QurekaAdId;
      }
    },
    'editGoogleAdForm.isActiveStaticBanners':function(value){
      if(value){
        this.disableQurekaBanner = false;
      }else{
        this.disableQurekaBanner = true;
        this.editGoogleAdForm.isActiveQureka = false;
      }
    }
  },
  computed: {},
  mounted: function () {},
  created: function () {},
  methods: {
    resetUpdateModel(){
        this.$emit('close');
        this.$refs.form.resetValidation();
    },
    updateGoogleAd(){
      if(this.$refs.form.validate()){
        axios.put(this.Node_JS_HOST + "/api/v1/web/google-ad",this.editGoogleAdForm)
        .then((res) => {
            if(res){
                this.resetUpdateModel();
                this.snackbarText = 'Google Ad Updated';
                this.isShowSnackbar = true;
            }
        })
        .catch((error) => {
            this.errorHandle(error);
            this.snackbarText = 'Something went wrong please try again';
            this.isShowSnackbar = true;
        });
      }
    },
    increment () {
      this.editGoogleAdForm.interstitialCount = parseInt(this.editGoogleAdForm.interstitialCount,10) + 1
    },
    decrement () {
      this.editGoogleAdForm.interstitialCount = parseInt(this.editGoogleAdForm.interstitialCount,10) - 1
    },
    AddSampleAdValue(){
      this.editGoogleAdForm.isActive = true;
      this.editGoogleAdForm.isActiveQureka = true;
      this.editGoogleAdForm.isActiveStaticBanners = true;
      this.disableQurekaBanner = false;
      this.editGoogleAdForm.appId = this.sampleValue.appId;
      this.editGoogleAdForm.bannerId = this.sampleValue.bannerId;
      this.editGoogleAdForm.nativeId = this.sampleValue.nativeId;
      this.editGoogleAdForm.interstitialId = this.sampleValue.interstitialId;
      this.editGoogleAdForm.appOpenId = this.sampleValue.appOpenId;
      this.editGoogleAdForm.rewardVideoId = this.sampleValue.rewardVideoId;
    }
  },
};
</script>