<template>
  <div class="text-center">
    <v-dialog v-model="showModel" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Google Ads</span>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="AddSampleAdValue()">
            Set Sample Ads
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="App Package Name *"
                    :rules="[(v) => !!v || 'App Package Name is required']"
                    v-model="addGoogleAdForm.appPackageName"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="App Id *"
                    v-model="addGoogleAdForm.appId"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Banner Id *"
                    v-model="addGoogleAdForm.bannerId"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Native Id *"
                    v-model="addGoogleAdForm.nativeId"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="App Open Id *"
                    v-model="addGoogleAdForm.appOpenId"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Reward Video Id *"
                    v-model="addGoogleAdForm.rewardVideoId"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Interstitial Id *"
                    v-model="addGoogleAdForm.interstitialId"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field 
                    v-model="addGoogleAdForm.interstitialCount" 
                    type="number" 
                    label="Interstitial Count"
                    required
                    append-outer-icon="mdi-plus" 
                    @click:append-outer="increment" 
                    prepend-icon="mdi-minus" 
                    @click:prepend="decrement"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-checkbox
                    label="Active"
                    v-model="addGoogleAdForm.isActive"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    label="Active Static Banners"
                    v-model="addGoogleAdForm.isActiveStaticBanners"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    label="Active Qureka"
                    :disabled="disableQurekaBanner"
                    v-model="addGoogleAdForm.isActiveQureka"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    label="Active IGaming"
                    v-model="addGoogleAdForm.isIGaming"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-combobox multiple
                    v-model="addGoogleAdForm.tags" 
                    label="Tags"
                    append-icon
                    chips
                    deletable-chips
                    class="tag-input"
                  >
                  </v-combobox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetAddGoogleAdModel()">
            Close
          </v-btn>
          <v-btn color="primary" @click="saveGoogleAd()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar
      :text="snackbarText"
      :showSnackbar="isShowSnackbar"
      @hide="isShowSnackbar = false"
    ></snackbar>
  </div>
</template>
<script>
import axios from "axios";
import Snackbar from "../Snackbar.vue";
export default {
  name: "createGoogleAds",
  components: {
    Snackbar,
  },
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: "",
    allGoogleAds: [],
    headers: [
      { text: "App Package Name" },
      { text: "Is Active" },
      { text: "Interstitial Count" },
      { text: "Enable QurekaAd" },
      { text: "App Id" },
      { text: "Banner Id" },
      { text: "Native Id" },
      { text: "Interstitial Id" },
      { text: "App Open Id" },
      { text: "Action" },
    ],
    snackbarText:'',
    isShowSnackbar:false,
    tableLoading: true,
    addGoogleAdForm: {
        appPackageName: '',
        appId: '',
        bannerId: '',
        nativeId: '',
        interstitialId: '',
        appOpenId: '',
        isActive: true,
        isActiveQureka:false,
        isActiveStaticBanners:false,
        interstitialCount:1,
        rewardVideoId: '',
        tags:[],
        isIGaming: ''
    },
    sampleValue: {
      appId : 'ca-app-pub-3940256099942544~3347511713',
      bannerId : 'ca-app-pub-3940256099942544/6300978111',
      nativeId : 'ca-app-pub-3940256099942544/2247696110',
      interstitialId : 'ca-app-pub-3940256099942544/1033173712',
      appOpenId : 'ca-app-pub-3940256099942544/3419835294',
      rewardVideoId : 'ca-app-pub-3940256099942544/5224354917',
    },
    disableQurekaBanner:true,
    valid: true,
  }),
  watch: {
    'addGoogleAdForm.isActiveStaticBanners':function(value){
      if(value){
        this.disableQurekaBanner = false;
      }else{
        this.disableQurekaBanner = true;
        this.addGoogleAdForm.isActiveQureka = false;
      }
    }
  },
  computed: {},
  mounted: function () {
  },
  created: function () {},
  methods: {
    resetAddGoogleAdModel() {
      this.addGoogleAdForm.appPackageName = "";
      this.addGoogleAdForm.isActive = true;
      this.addGoogleAdForm.isActiveQureka = false;
      this.addGoogleAdForm.isActiveStaticBanners = false;
      this.disableQurekaBanner = true;
      this.addGoogleAdForm.interstitialCount = 1;
      this.addGoogleAdForm.appId = "";
      this.addGoogleAdForm.bannerId = "";
      this.addGoogleAdForm.nativeId = "";
      this.addGoogleAdForm.interstitialId = "";
      this.addGoogleAdForm.appOpenId = "";
      this.addGoogleAdForm.rewardVideoId = "";
      this.addGoogleAdForm.tags = [];
      this.$emit('close');
      this.$refs.form.resetValidation();
    },
    saveGoogleAd() {
      if (this.$refs.form.validate()) {
        axios.post(this.Node_JS_HOST + "/api/v1/web/google-ad",this.addGoogleAdForm)
          .then((res) => {
            if (res) {
              this.resetAddGoogleAdModel();
              this.snackbarText = "Google ad add Successfully";
              this.isShowSnackbar = true;
            }
          })
          .catch((error) => {
            this.errorHandle(error);
            this.snackbarText = "Please insert valid input";
            this.isShowSnackbar = true;
          });
      }
    },
    increment () {
      this.addGoogleAdForm.interstitialCount = parseInt(this.addGoogleAdForm.interstitialCount,10) + 1
    },
    decrement () {
      this.addGoogleAdForm.interstitialCount = parseInt(this.addGoogleAdForm.interstitialCount,10) - 1
    },
    AddSampleAdValue(){
      this.addGoogleAdForm.isActive = true;
      this.addGoogleAdForm.isActiveQureka = true;
      this.addGoogleAdForm.isActiveStaticBanners = true;
      this.addGoogleAdForm.isIGaming = true;
      this.disableQurekaBanner = false;
      this.addGoogleAdForm.appId = this.sampleValue.appId;
      this.addGoogleAdForm.bannerId = this.sampleValue.bannerId;
      this.addGoogleAdForm.nativeId = this.sampleValue.nativeId;
      this.addGoogleAdForm.interstitialId = this.sampleValue.interstitialId;
      this.addGoogleAdForm.appOpenId = this.sampleValue.appOpenId;
      this.addGoogleAdForm.rewardVideoId = this.sampleValue.rewardVideoId;
    }
  },
};
</script>