<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="mb-7">
          <v-toolbar flat>
            <v-toolbar-title> Google Ads </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="addGoogleAdsModal = true"
              class="mx-2"
              fab
              dark
              small
              color="#1976d2"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-data-table
              class="mx-5"
              :loading="tableLoading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :search="search"
              :items="allGoogleAds"
              :custom-filter="filterOnlyCapsText"
              :footer-props="{
                'items-per-page-options': [50, 100, 150, 200, 300, -1]
              }"
              :items-per-page="50"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Search (Google Ads)"
                  class="mx-4"
                ></v-text-field>
              </template>
              <template v-slot:item="row">
                <tr>
                  <td class="d-flex align-center">
                    <v-img
                      v-if="row.item.appDetail"
                      :lazy-src="row.item.appDetail.playStoreImg"
                      max-height="25"
                      max-width="25"
                      class="mr-2"
                      :src="row.item.appDetail.playStoreImg"
                    ></v-img>
                    {{ row.item.appPackageName }}
                  </td>
                  <td class="text-truncate w-100" :ref="'app-id'" @click="changeClass('app-id')">{{ row.item.appId }}</td>
                  <td class="text-truncate w-100" :ref="'banner-id'" @click="changeClass('banner-id')">{{ row.item.bannerId }}</td>
                  <td class="text-truncate w-100" :ref="'native-id'" @click="changeClass('native-id')">{{ row.item.nativeId }}</td>
                  <td class="text-truncate w-100" :ref="'interstitial-id'" @click="changeClass('interstitial-id')">{{ row.item.interstitialId }}</td>
                  <td class="text-truncate w-100" :ref="'app-open-id'" @click="changeClass('app-open-id')">{{ row.item.appOpenId }}</td>
                  <td class="text-truncate w-100" :ref="'reward-id'" @click="changeClass('reward-id')">{{ row.item.rewardVideoId }}</td>
                  <td>
                    <v-chip x-small label :color="getColor(row.item.isActive)" dark>
                      {{ row.item.isActive }}
                    </v-chip>
                  </td>
                  <td>
                    <v-chip x-small label v-for="tag in row.item.tags" :key="tag" :color="getColor(tag)" dark class="mr-1">
                      {{ tag }}
                    </v-chip>
                  </td>
                  <td  class="text-truncate w-100" :ref="'createdon'" @click="changeClass('createdon')">{{ dateFormat(row.item.createdon) }}</td>
                  <td style="min-width: 92px;"><span><a @click="UpdateGoogleAdModel(row.item)"><v-icon color="primary">mdi-pencil</v-icon></a> | <a @click="showDeleteDilog(row.item)"><v-icon color="red">mdi-delete</v-icon></a></span></td>
                </tr>
              </template>
              <template v-slot:no-results>NO RESULTS HERE</template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <snackbar :text="snackbarText" :showSnackbar="isShowSnackbar" @hide="isShowSnackbar = false"></snackbar>
    <create-googlead :show-model="addGoogleAdsModal" @close="closeGoogleAd"></create-googlead>
    <delete-dialog :contentText="deleteText" :showModel="showDeleteModel" :id="deleteId" :headerText="'Delete Google Ads'" @action="deleteAction"></delete-dialog>
    <update-googlead :showModel="showUpdateGoogleAdModel" :googlead-detail="updateGoogleAdDetail" @close="updateGoogleAd"></update-googlead>
  </v-container>
</template>
<script>
import axios from "axios";
import UpdateGooglead from './UpdateGooglead.vue';
import DeleteDialog from '../DeleteDialog.vue';
import Snackbar from '../Snackbar.vue';
import CreateGooglead from './CreateGooglead.vue';
export default {
  name: "googleAds",
  components: {
    DeleteDialog,
    Snackbar,
    UpdateGooglead,
    CreateGooglead,
  },
  data: () => ({
    search: "",
    allGoogleAds: [],
    headers: [
      { text: "App Package Name", value: "appPackageName"},
      { text: "App Id", value: "appId"},
      { text: "Banner Id", value: "bannerId"},
      { text: "Native Id", value: "nativeId"},
      { text: "Interstitial Id", value: "interstitialId"},
      { text: "App Open Id", value: "appOpenId"},
      { text: "Reward Video Id", value: "rewardVideoId"},
      { text: "Is Active",value: "isActive"},
      { text: "Tags", value: "tags"},
      { text: "Created At", value: "createdon"},
      { text: "Action"},
    ],
    addGoogleAdsModal: false,
    tableLoading: true,
    valid: true,
    deleteText:'',
    showDeleteModel:false,
    deleteId:'',
    showUpdateGoogleAdModel:false,
    updateGoogleAdDetail:{}
  }),
  computed: {},
  mounted: function () {
    this.getGoogleAds();
  },
  created: function () {},
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    getGoogleAds() {
      this.tableLoading = true;
      axios.get(this.Node_JS_HOST + "/api/v1/web/google-ad")
        .then((res) => {
          const response = res.data;
          this.allGoogleAds = response.data;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.errorHandle(error);
          this.tableLoading = false;
        });
    },
    getColor(isActive) {
      if (isActive) return "green";
      else return "orange";
    },
    closeGoogleAd(){
      this.getGoogleAds();
      this.addGoogleAdsModal = false;
    },
    UpdateGoogleAdModel(googleAdDatail){
      this.showUpdateGoogleAdModel = true;
      this.updateGoogleAdDetail = googleAdDatail;
    },
    updateGoogleAd(){
      this.getGoogleAds();
      this.showUpdateGoogleAdModel = false
      this.updateGoogleAdDetail = {};
    },
    showDeleteDilog(googleAdDetail){
      this.deleteText = 'Are you sure, You want to delete this <b>'+googleAdDetail.appPackageName +'</b> Google ad.';
      this.deleteId = googleAdDetail._id;
      this.showDeleteModel = true;
    },
    deleteAction(actionType,id){
      if(actionType == 'delete'){
           axios.delete(this.Node_JS_HOST + "/api/v1/web/google-ad/"+id)
            .then((res) => {
              if(res){
                this.getGoogleAds();
                this.snackbarText = 'Google Ad Deleted';
                this.isShowSnackbar = true;
              }
            })
            .catch((error) => {
              this.errorHandle(error);
              this.snackbarText = 'Something went wrong please try again';
              this.isShowSnackbar = true;
            });
      }
      this.showDeleteModel = false;
      this.deleteId = '';
    },
    changeClass: function (refId) {
      if(this.$refs[refId].classList.contains('w-100')){
        this.$refs[refId].classList.add('w-unset');
        this.$refs[refId].classList.remove('w-100');
      }else{
        this.$refs[refId].classList.add('w-100');
        this.$refs[refId].classList.remove('w-unset');
      }
      return;
    }
  },
};
</script>
<style scoped>
  ::v-deep th {
    white-space: nowrap!important;
  }
  .w-100{
    max-width: 100px;
  }
  .w-unset{
    max-width: unset;
  }
</style>